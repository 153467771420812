import React from "react";
import styled from '@emotion/styled'
import {keyframes} from '@emotion/react'
import { CircularProgress } from '@mui/material'
const blink = keyframes`
    0% {
        background-color: red;
        color: white;
    }
    50% {
        background-color: transparent;
        color: transparent;
    }
    100% {
        background-color: red;
        color: white;
    }
`

export const TopNotice = styled.div`
    cursor: pointer;
    background-color: red;
    color: white;
    font-weight: bold;
    animation: ${blink} 2s linear infinite;
    padding: .5em;
    text-align: center;
    text-decoration: none;
`
const CardWrapper = styled.div`
    text-align: left;
    margin: 1em;
`
const Caption = styled.div`
    font-weight: bold;
    margin-bottom: .5em;
`

export const Container = styled.div`
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    text-align: center;
    height: 100%;
    background: #0c253df2;
    color: #fff;
    font-size: 15px;
    & a, & a:active, & a:hover, & a:visited {
        text-decoration: none;
    }
`

export function Card({caption, children}: {caption:string, children: React.ReactNode}) {
    return (
    <CardWrapper>
        <Caption>{caption}</Caption>
        {children}
    </CardWrapper>
    )
}
const Row = styled.div` 
    display: flex;
    margin-bottom: 0.5em;
    flex-flow: row nowrap;
    justify-content: space-between;
`
const RowCaption = styled.div`
    color: #ffffffa8
`
const RowValue = styled.div`
    color: #ffd66d
`
export function CardRow({caption, value, error}: {caption: string, value: string, error?: boolean}){
    return (
        <Row>
           <RowCaption>{caption}</RowCaption> 
           <RowValue>{value}</RowValue>
        </Row>
    )
}
export const CardValue = styled.div`
    font-size: 3em;
    color: #8275ff;
`
export const Header  = styled.div`
    text-align: left;
    font-size: 0.6em;
    padding: 0.5em ;
    background: rgba(0 0 0 /0.5);
    color: #ffffffa8;
`

export const NoticeBox = styled.div`
    margin: 48px auto;
    text-align: center;
    max-width: 500px;
`
/** Spinner */
export const Spinner = () => <CircularProgress/>