import { ErrorJson } from "src/../../common/src/api"
export const ERR_NO_RESPONSE       : ErrorJson = { code: -1, message: "Ошибка получения данных"} 
export const ERR_REQUEST_NOT_SENT  : ErrorJson = { code: -2, message: "Ошибка при выполнении запроса"} 
export const ERR_UNKOWN            : ErrorJson = { code: -3, message: "Неизвестная ошибка"} 
export const ERR_PARSING_RESPONSE  : ErrorJson = { code: -4, message: "Ошибка обработки результата запроса"}

export class FetchDataError extends Error {
    errorJson: ErrorJson
    constructor(errorJson: ErrorJson) {
        super(errorJson.message)
        this.errorJson = errorJson
    }
}