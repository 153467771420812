import React, { useCallback, useState } from 'react'
import {useDropzone, DropzoneRootProps} from 'react-dropzone'
import { Theme } from '@mui/material/styles'
import {Delete as DeleteIcon} from '@mui/icons-material'
import {SiMicrosoftexcel} from 'react-icons/si'
import useApiEndpoint from 'src/lib/useApiEndpoint'
import { API_UPLOAD } from 'src/../../common/src/api'
import useNotice from 'src/lib/useNotice'
import { LoadingButton } from '@mui/lab'
import {Card, IconButton, Button, CardContent, Typography, ListItem, ListItemText, ListItemIcon, SvgIcon, List, CardActions, Box } from '@mui/material'
import {usePubSub} from 'src/components/PubSubProvider'
import { REFETCH_UPLOAD_LOG } from 'src/lib/constants'

const getColor = (props: Partial<DropzoneRootProps>, theme: Theme  ) => {
  if (props.isDragAccept) {
      return theme.palette.success.main
  }
  if (props.isDragReject) {
      return theme.palette.error.main
  }
  if (props.isFocused) {
      return theme.palette.primary.main
  }
  return theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300]
}

class DropzoneContainer extends React.Component<DropzoneRootProps>{
    render() {
        const {isFocused, isDragAccept, isDragReject, ...props} = this.props
        return (
        <Box {...props} sx={
            (theme) => ({
                textAlign: 'center',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                p: 2,    
                borderRadius: 1,
                borderStyle: 'dashed',
                borderWidth: 2 ,
                borderColor: getColor({isFocused, isDragAccept, isDragReject}, theme),
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.secondary,
                outline: 'none',
                transition: 'border .24s ease-in-out'
        })}/>
        )
    }
} 

export default function Uploader() {
    const pubSub = usePubSub()
    const {post, isLoading, error,success, delayed}= useApiEndpoint(API_UPLOAD, {successMessage: "Загрузка завершена"})
    useNotice({error,success})

    const [files,setFiles] = useState<Array<File>>([])

    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        setFiles([...acceptedFiles, ...files])
    }, [files])

    const accept = {
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
    }
    const {getRootProps, getInputProps, isFocused, isDragAccept,isDragReject} = useDropzone({onDrop, accept, disabled: isLoading})

    const createRemoveHandler = (file: File)  => () => {
        const newFiles = [...files]
        newFiles.splice(newFiles.indexOf(file), 1)
        setFiles(newFiles)
    }

    const removeAll = () => {
        setFiles([])
    }

    const uploadHandler = async () => {
        const data = new FormData()
        files.forEach((file) => {
            data.append(`datafiles[]`,file)
        })
        const res = await post({data,headers:{'Content-Type': 'multipart/form-data'} })
        if (res.success) {
            removeAll()
        }
        pubSub?.emit(REFETCH_UPLOAD_LOG)

    }

    return (
        <Card>
            <CardContent>
                <Typography gutterBottom variant='h5'>
                    Загрузка файлов
                </Typography>
                <DropzoneContainer {...getRootProps({isFocused, isDragAccept, isDragReject})}>
                    <input {...getInputProps()} /> 
                    <Typography variant='body1'>
                        Нажмите, чтобы добавить файлы в список, или просто перетащите их сюда
                    </Typography>
                </DropzoneContainer>
                {files.length > 0 && 
                    <React.Fragment>
                        <List dense>
                            { files.map((file, index) => (
                                <ListItem key={index} 
                                    secondaryAction={<IconButton disabled={isLoading} onClick={createRemoveHandler(file)} children={<DeleteIcon/>} />}>
                                    <ListItemIcon><SvgIcon color="success"><SiMicrosoftexcel/></SvgIcon></ListItemIcon>
                                    <ListItemText primary={file.name} secondary={`${file.size} bytes`}/>
                                </ListItem>
                            ))}
                        </List>
                        <CardActions>
                            <Button disabled={isLoading} onClick={removeAll} children="Очистить"/>
                            <LoadingButton  disabled={isLoading} loading={isLoading && !delayed} onClick={uploadHandler} children="Загрузить"/>
                        </CardActions>
                    </React.Fragment>
                }                
            </CardContent>
        </Card>
    )
}