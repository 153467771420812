import * as React from 'react'
import {UserRoles} from 'src/../../common/src/api'
import { useLocation, Location, Navigate } from 'react-router-dom'
import { useAppInfo } from 'src/lib/api'
import ErrorBox, {WarningBox} from 'src/components/ErrorBox'

export type LocationState = { from: Location } | undefined

interface AuthUsersOnlyProps {
    children: React.ReactElement 
    roles?:  UserRoles[]
}

export default function AuthUsersOnly({children, roles}: AuthUsersOnlyProps) {
    const {data: appInfo, error} = useAppInfo()
    const location = useLocation()
    
    const user = appInfo?.user

    if (error) {
        return <ErrorBox error={error}/>
    }
    if (!user && location.pathname !=="/login") {
        return <Navigate to={"/login"} replace={true} state={{from: location}}/>
    }  
    if (user && (user.role === UserRoles.Admin || !roles || roles.includes(user.role))) {
        return children
    }else {
        return <WarningBox header="Недостаточно прав доступа" message='Попробуйте войти под другой учетной записью'/>
    }
}