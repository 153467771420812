import InfoField from './InfoField'
import {Tooltip} from '@mui/material'
import {MutexData} from 'src/../../common/src/api'
import React from 'react'
import ms from 'ms'

import {
    Circle as CircleIcon
    
 } from '@mui/icons-material'

interface MutexFieldProps {
    mutex?: MutexData
    caption: string
    threshold?: string 
}

export default function MutexField({threshold, caption, mutex}: MutexFieldProps) {
    const {enabled, busy, busyTime} = mutex || {}
    const error = threshold !== undefined && busyTime && busyTime > ms(threshold)

    return  <InfoField variant='custom' caption={caption} value={
        <React.Fragment>
            <Tooltip title={enabled? 'Включено' : 'Выключено'}>
                <CircleIcon fontSize='large' sx={{color: enabled ? 'lightgreen': 'red'}}/>
            </Tooltip>
            <Tooltip title={error ? `Работатет дольше ${ms(busyTime, {long: true})}` : busy ? "Работатет": "Свободно"}>
                <CircleIcon fontSize='large' sx={{color: error ? 'red' : busy ? 'gold' : 'lightGray'}}/>
            </Tooltip>
        </React.Fragment>
        }/>

}
//color={ error ? 'error' : busy ? 'warning' : 'disabled'}