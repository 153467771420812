import { API_AMO_REFRESH_TOKEN 
} from  'src/../../common/src/api'
import useApiEndpoint from 'src/lib/useApiEndpoint'
import ConfirmButton from './ConfirmButton'

export default function RefreshAmoTokenButton() {
    const {post}  = useApiEndpoint(API_AMO_REFRESH_TOKEN)
    const onClick = async () => {
        await post()
    }
    return <ConfirmButton confirmation="Получить новый токен?" onClick={onClick}>Обновить токен</ConfirmButton>
}
