import * as React from 'react'
import { UserRoles } from 'src/../../common/src/api'
import Uploader from 'src/components/Uploader'
import Dashboard from 'src/components/Dashboard' 
import VisibleFor from 'src/components/VisibleFor'
import LeadLog from 'src/components/LeadLogTable'
import ControlPanel from 'src/components/ControlPanel'
import { Stack } from '@mui/material'
import { useAppInfo } from 'src/lib/api'
//import { UploadLog } from 'src/components/UploadLog'
import UploadLogTable from 'src/components/UploadLogTable'
import {Tabs, Tab, Box } from '@mui/material'
import {Navigate, Routes, Route,  useNavigate,  useLocation, matchPath, resolvePath } from 'react-router-dom'

interface Section {
    path: string
    label: string,
    forAll?: boolean,
    onlyFor?: UserRoles[]
    content: () => React.ReactNode
}

const DEFAULT_TAB_PATH = 'monitor'
const tabsData: Section[] = [
    {
        path: DEFAULT_TAB_PATH,
        label: 'Мониторинг',
        forAll: true,
        content: ()=> <Stack spacing={2}>
                        <Dashboard/>
                        <VisibleFor roles={[UserRoles.Admin]}>
                            <ControlPanel/>
                        </VisibleFor>
                    </Stack>
    },

    {
        path: 'upload',
        label: 'Загрузка данных',
        onlyFor: [UserRoles.Admin,UserRoles.Operator],
        content: () =>  
            <Stack spacing={2}>
                <Uploader/>
                <UploadLogTable/>
            </Stack>
    },
    {
        path: 'lead_log',
        label: 'Журнал изменений',
        forAll: true,
        content: () => <LeadLog/>
    },
    /*
    {
        path: 'control',
        label: 'Администрирование',
        onlyFor: [UserRoles.Admin],
        content: () => <ControlPanel/>
    }
    */
]

function useRouteMatch(tabs: Section[], path: string) {
  const { pathname } = useLocation();
  for (let i = 0; i < tabs.length; i += 1) {
    const tabPathname = resolvePath(tabs[i].path, path).pathname
    const possibleMatch = matchPath(tabPathname, pathname);
    if (possibleMatch !== null) {
      return tabs[i].path;
    }
  }
  return null;
}
function useVisibleTabs(tabs: Section[]): Section[]  {
    const role = useAppInfo().data?.user?.role
    return tabs.filter(({onlyFor: roles, forAll: all})=>{
        return all || (roles && role && roles.includes(role)) 
    })
}
export default function MainScreen({path}:{path: string}) {
    const navigate = useNavigate()
    const tabs = useVisibleTabs(tabsData)
    const currentTab = useRouteMatch(tabs,path)  

    if (!currentTab) {
        return <Navigate to={DEFAULT_TAB_PATH}/>
    }

    const createTabClickHandler = (path: string) => () => {
        navigate(path)
    }
    return (
        <React.Fragment>
            <Tabs value={currentTab}>
                {tabs.map(({label, path})=><Tab key={path} label={label} value={path} onClick={createTabClickHandler(path)}/> )}
            </Tabs>
            <Box sx={{flex: '1 1 auto', overflow: 'auto', display: 'flex', flexFlow: 'column nowrap', p: 2}}>
            <Routes>
                {tabsData.map(({path, forAll, onlyFor, content})=><Route key={path} path={path} element={<VisibleFor all={forAll} roles={onlyFor}>{content()}</VisibleFor>}></Route>)}
            </Routes>
            </Box>
            <Box sx={{background: 'tomato'}}></Box>
        </React.Fragment>
    )  
}