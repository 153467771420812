import {ButtonProps, Button, Dialog, DialogContent, DialogActions} from '@mui/material'
import React from 'react'

interface ConfirmButtonProps extends ButtonProps{
    onClick?: () => void
    confirmation?: React.ReactNode 
}
export default function ConfirmButton({ onClick, confirmation, ...props}: ConfirmButtonProps) {
    const [open , setOpen] = React.useState<boolean>(false) 
    const openHandler = () => {
        setOpen(true)
    }
    const closeHandler = () => {
        setOpen(false)
    }
    const confirmHandler = () => {
        setOpen(false)
        onClick && onClick()
    }
    return (
        <React.Fragment>

        <Button {...props} onClick={openHandler}/>
        <Dialog onClose={closeHandler} open={open}>
            <DialogContent>
                {confirmation}
            </DialogContent>
            <DialogActions>
                <Button onClick={confirmHandler}>Да</Button>
                <Button onClick={closeHandler}>Нет</Button>
            </DialogActions>
        </Dialog> 
        </React.Fragment>
    )

}