import {API_CONTROL, ControlReq, ControlPanelData} from 'src/../../common/src/api'
import useApiEndpoint from 'src/lib/useApiEndpoint'
import {useControlPanel} from 'src/lib/api'
import {
    Card,
    CardContent,
    Switch,
    Typography,
    FormControlLabel,
    styled
} from '@mui/material'
import ConfirmButton from './ConfirmButton'
import { usePubSub } from './PubSubProvider'
import { REFETCH_DASHBOARD } from 'src/lib/constants'
import RegisterNewIntegrationButton from './RegisterNewIntegrationButton'
import RefreshAmoTokenButton from './RefreshAmoTokenButton'

const defaultData: ControlPanelData = {
    uploadEnabled: false,
    leadProcessingEnabled: false ,
}
const ControlRow = styled('div') ({
    display: 'flex',
    flexFlow: 'row nowrap', 
    justifyContent: 'space-between', 
    alignItems: 'center'
})
export default function ControlPanel() {
    const {data, mutate} = useControlPanel()
    const {post} = useApiEndpoint<any, ControlReq>(API_CONTROL)
    const pubSub = usePubSub()

    const disabled = !data 
    const { uploadEnabled, leadProcessingEnabled} = data || defaultData 

    const refreshDashboard = () =>{
        pubSub?.emit(REFETCH_DASHBOARD)
    }
    const uploadEnabledToggleHandler = async () =>{
        await post({data: {setUploadEnabled: !uploadEnabled} })
        await mutate()
        refreshDashboard()
    }
    const leadProcessingToggleHandler = async () => {
        await post({data: {setLeadProcessingEnabled: !leadProcessingEnabled}})
        mutate()
        refreshDashboard()
    }
    const resetUploadLockHandler = async () => {
        await post({data: { resetUploadLock: true}})
        refreshDashboard()
    }
    const resetLeadProcessingLockHandler = async () => {
        await post({data: {resetLeadProcessingLock: true}})
        refreshDashboard()
    }
    return (
            <Card>
                <CardContent>
                    <Typography gutterBottom variant='h5'>
                        Панель управления
                    </Typography> 
                    <ControlRow>
                        <FormControlLabel label={"Синхронизация с amoCRM"} control={
                            <Switch disabled={disabled} checked={leadProcessingEnabled} onChange={leadProcessingToggleHandler}/>
                            }/>
                        <ConfirmButton variant='outlined' size='small' color='error' children='Сброс' 
                            confirmation='Сбросить флаг блокировки синхронизации с amoCRM?'
                            onClick={ resetLeadProcessingLockHandler } 
                            />
                    </ControlRow>
                    <ControlRow>
                        <FormControlLabel label={"Возможность загрузки данных через файлы"} control={
                            <Switch disabled={disabled} checked={uploadEnabled} onChange={uploadEnabledToggleHandler}/>
                        }/>
                        <ConfirmButton  variant='outlined' size='small' color='error' children='Сброс' 
                            confirmation='Сбросить флаг блокировки загрузки файлов?'
                            onClick={resetUploadLockHandler}
                            />
                    </ControlRow>
                    <RegisterNewIntegrationButton/>
                    <RefreshAmoTokenButton/>
                </CardContent>
            </Card>
    )
}