import { WarningAmberRounded as ErrorIcon} from "@mui/icons-material";
import { Card, CardActions, CardContent, CardMedia, Typography } from "@mui/material";

export default function ErrorBox({error}: {error: Error}) {
    return <Card sx={{mx:'auto', my: 6, maxWidth: 300, textAlign: 'center'}}>
        <CardMedia sx={{bgcolor: 'error.main', color: 'error.contrastText', verticalAlign: 'bottom', fontSize: '3rem', py: 1}} >
            <ErrorIcon sx={{display: 'block', mx: 'auto'}}fontSize="inherit"/>
            <Typography color="inherit" variant="h5" fontWeight='bold' >
                ERROR
            </Typography>
        </CardMedia>
        <CardContent>
            <Typography variant="body1">
                {error.message}
            </Typography>
        </CardContent>
    </Card>
}
interface BoxProps {
    message?: string, header?: string, actions?: React.ReactNode
}
export function WarningBox({message, header,actions}: BoxProps ) {
    return <Card sx={{mx:'auto', my: 6, maxWidth: 400, textAlign: 'center'}}>
        <CardMedia sx={{bgcolor: 'warning.main', color: 'warning.contrastText', verticalAlign: 'bottom', fontSize: '3rem', py: 1}} >
            <ErrorIcon sx={{display: 'block', mx: 'auto'}}fontSize="inherit"/>
            <Typography color="inherit" variant="h6" fontWeight='bold' >
               WARNING 
            </Typography>
        </CardMedia>
        <CardContent>
            {!!header && <Typography gutterBottom variant="h6">
                {header}
            </Typography> }
            {!!message && <Typography variant="body1">
                {message}
            </Typography>}
        </CardContent>
        <CardActions>
            {actions}
        </CardActions>
    </Card>

}