import * as React from 'react'
import {Alert, Box, Typography} from '@mui/material'
import { formatTimestamp } from 'src/lib/tools'
import { useLeadEventDetails } from 'src/lib/api'
import LeadingProgress from './LoadingProgress'

/*const LeadDetail = {
    _id: 11351,
    processed: false,
    organization_id: 'anmo',
    division: 'VO',
    happenedAt: 1655972134021,
    type: 'UPDATE',
    rowIndex: 23,
    lead: {
      programId: '006668-2019',
      programName: 'Базовые знания по фармакологии для сотрудников аптечной организации',
      zetAmount: 36,
      startDate: '22.08.2022',
      endDate: '27.08.2022',
      studentName: 'Селезнева  Наталья Юрьевна',
      snils: '14615012424',
      leadId: 'NMOV-0421543-2022',
      leadDate: '26.05.2022',
      leadStatus: 'Сформирована',
      studentRegion: 'Архангельская область',
      studentEmployer: 'Вита. Норд,',
      studentJobTitle: 'провизор',
      leadPaymentOption: 'Договорная',
      price: '2500',
      payer: 'Физическое лицо',
      email: 'gusakovanat11@gmail.com',
      phone: '79643007171'
    },
    user_id: 'USR0002',
    diff: {
      studentName: {
        oldValue: 'Гусакова Наталья Юрьевна',
        newValue: 'Селезнева  Наталья Юрьевна'
      }
    }
}
*/

type LeadDetailsProps = {
    eventId: number
}

export default function LeadEventDetails({eventId}: LeadDetailsProps) {
    const {error, data: leadEventDetails, isLoading} = useLeadEventDetails({params:{eventId}})
    let content: React.ReactNode = undefined
    if (isLoading){
      content = <LeadingProgress/>
    }else if (error) {
      content = <Alert severity='error'>{error.message}</Alert>
    }else {
      content = <React.Fragment>
            <Typography variant='h5'> Запись журнала </Typography>
            <Typography component='h5' variant='subtitle1' color={'text.secondary'}>{`#${eventId} от ${
                formatTimestamp(leadEventDetails?.happenedAt)}` }</Typography>
            <pre>{JSON.stringify(leadEventDetails, null, 2) }</pre>
      </React.Fragment>
    }
    return  (
    <Box sx={{border: 1, p: 2, borderColor: theme => theme.controls.borderColor(theme) ,
        width: '100%', height: '100%', overflow: 'auto'}}>
          {content}
    </Box>
    )
}