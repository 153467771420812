import { Typography, Box, SxProps} from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import * as React from 'react'

type InfoFieldVariant = 'big' | 'small' | 'custom'


interface InfoFieldProps {
    value:  React.ReactNode 
    variant?: InfoFieldVariant
    caption: string
    disabled?: boolean
    alarm?: 'red' | 'yellow'| 'green'
}

export default function InfoField({alarm, caption, disabled, variant,value}:InfoFieldProps) {
    let valueVariant: Variant = 'body1'
    if (variant === 'big'){
        valueVariant = 'h4'
    }else if (variant === 'small') {
        valueVariant = 'caption'
    }
    let sx: SxProps | undefined 
    switch (alarm) {
        case 'red' :
            sx = {backgroundColor:'red', color: 'white' }
            break
        case 'green':
            sx = {backgroundColor: 'lightGreen' }
            break
        case 'yellow':
            sx = {backgroundColor: 'gold'}
            break
    }


    return (
        <Box sx={{flexShrink: 0}}>
            <Typography variant='caption' color={'text.secondary'}>{caption}</Typography>
            {variant === 'custom' ? <div>{value}</div> :
                <Typography variant={valueVariant}>
                    <Box sx={sx ? {...sx, display: 'inline-block', textAlign: 'center', borderRadius: 1, paddingX: 2, }: {}}>
                        {disabled ? '-' : value}
                    </Box>
                </Typography>
            }
        </Box>
    )
}