import {LeadEventTypes, Lead, EduDivisions, LeadDiff, LeadFields} from './lead'
export type ParamsDic<T> = Record<keyof T, string>
/** user roles */
export enum UserRoles {
    Admin = "ADMIN",
    Operator = "OPERATOR",
    Viewer = "VIEWER",
    Service = "SERVICE",
}

/** api endpoints */
export const API_ACADEMY = '/academy'
export const API_INFO   = '/info'
export const API_LOGIN  = '/login'
export const API_LOGOUT = '/logout'
export const API_WIDGET_DATA = '/widget_data'
export const API_UPLOAD = '/upload'
export const API_DASHBOARD = '/dashboard'
export const API_CONTROL = '/control'
export const API_LEAD_LOG = '/lead_log'
export const API_LEAD_EVENT_DETAILS = '/lead_event_details'
export const API_UPLOAD_LOG ='/upload_log'
export const API_AMO_REGISTER = '/amo_register'
export const API_AMO_REFRESH_TOKEN = '/amo_refresh_token'
/** base interface for every api result */
export interface ApiRes<T = any> {
    error?: ErrorJson
    success: boolean 
    data?: T 
}

/** error */
export interface ErrorJson {
    code: number,
    message: string,
    desc?: string
}

/** AppInfo - general app info */
export interface AppInfo {
    serverVersion: string 
    user?: {
        name: string | undefined
        role: UserRoles
    }
}

/** login request params */
export interface ApiLoginReq {
    login: string,
    password: string,
}

/** successful login response */
export interface LoginRes extends ApiRes{}

/** widget data */
export interface WidgetData {
    alarm?: boolean
    updatedAt: number,
    totals: {
        newLeads: number,
        rejectedLeads: number
    },
    status: Array<{caption: string, value: string, error?: boolean}>
}
export interface UploadResult {
    files: [{
        name: string
        organisation: string
        rowsTotal: number
        rowsWithChanges: number
        leadsNew: number
        leadsCanceled: number 
    }]
}
export interface AmoIntegrationInfo {
    registered: boolean
    account?: string
    token?: AmoTokenInfo
    lastUsedAt?: number
}
export interface AmoTokenInfo {
    expiresAt: number
    createdAt: number
}
export interface DashboardData {
    upload: MutexData 
    leadProcessing:  LeadProcessingServiceInfo
    amoIntegrationInfo: AmoIntegrationInfo
}
export interface LeadProcessingServiceInfo {
    queueLength: number
    mutex: MutexData
    service: {
        active: boolean
        errorsCount: number
        threshold: number
        currentDelay: number
    }
}
export interface MutexData {
    enabled: boolean
    busy: boolean
    busyTime: number
}
export interface ControlPanelData {
    uploadEnabled: boolean
    leadProcessingEnabled: boolean
}

export interface ControlReq {
    setUploadEnabled?: boolean
    setLeadProcessingEnabled?: boolean
    resetUploadLock?: boolean
    resetLeadProcessingLock?: Boolean
}
export interface LeadLogRecord {
    _id: number
    organization: string,
    processed: boolean,
    eduDivision: string,
    happenedAt: number,
    type: LeadEventTypes,
    leadId: string,
    leadDateTs: number,        //Дата подачи заявки (в виде timestamp)
    studentName: string,
    programName: string     //Название программы
}

export type LeadLog = Array<LeadLogRecord>

export type AcademyLeadLogRecord = LeadFields & {
    _id: number
}

export type AcademyLeadLog = Array<AcademyLeadLogRecord>
export interface PeriodParams {
    periodStart: number , 
    periodEnd: number
}
export type LeadLogReq = PeriodParams & {
}
export interface AcademyLeadLogReq {
  edgeId: number,
  limit: number
}

export interface LeadEventDetails {
    _id?: number
    organization_id: string //организация
    happenedAt: number      //когда произошло 
    type: LeadEventTypes    //NEW , UPDATE, CANCEL
    lead: Lead              //данные заявки
    rowIndex: number        //номер строки в файле
    user_id?: string        //пользователь
    diff?: LeadDiff         //разница значений заявки
    processed: boolean      //обработана (отражена в CRM)
    division: EduDivisions  //раздел портала (ВО или СПО)
    amoLastProcLog?: AmoProcLogRecord 
    amoProcLog?: AmoProcLogRecord[]
}
export type FieldsDiff = Record<string, {
            oldValue: any
            newValue: any
        }> 
export interface AmoProcLogRecord {
    success?: boolean,
    error?: boolean,
    contact?: {
        id: number
        action: 'CREATE' | 'UPDATE',
        diff? : FieldsDiff
    },
    lead?: {
        id: number, 
        action: 'CREATE' | 'UPDATE',
        diff?: FieldsDiff
    },
    taskCancel?: {
        id: number
        action: 'CREATE' | 'UPDATE'
    }
    errorData?: any ,//should be filtered out 
    happenedAt: number
}

export type LeadEventDetailsReq = {
    eventId: number
}
export type UploadLogReq = PeriodParams & {
}
export enum FileParsingResult {
    success = 'SUCCESS',
    skip    = 'SKIP',
    error   = 'ERROR'
}
export interface UploadLogRecord {
    _id: string
    happenedAt: number
    userName: string
    fileName: string
    fileSize: number 
    fileHash: string
    parsing?: FileParsingResult
    errorMessage?: string
    stats?: {
        totalRows: number
        eventTypes: Record<LeadEventTypes, number>
    }

}
export type UploadLog = Array<UploadLogRecord>

export interface AmoRegisterNewIntegrationReq {
    url: string
    clientId: string
    clientSecret: string
    code: string
    redirectUrl: string
}