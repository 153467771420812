import dayjs from "dayjs"

export function formatDateTimestamp(val?: number): string {
       return val && val !==0 ? dayjs(val).format('YYYY.MM.DD') : '' 
}
export function formatTimestamp(val?: number): string  {
       return val && val !==0 ? dayjs(val).format('YYYY.MM.DD HH:mm:ss') : '' 
}
export function getStartOfDay(val?: Date | null): number {
       return dayjs(val).startOf('day').valueOf()
}
export function getEndOfDay(val? : Date | null): number {
       return dayjs(val).endOf('day').valueOf()
}
export function getStartOfDayAgo(amount: number, val? : Date | null) : number {
       return dayjs(val).subtract(amount,'days').startOf('day').valueOf()
}
       
