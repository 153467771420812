import * as React from 'react'
import {Skeleton} from '@mui/material'

interface LeadingProgressProps {
    lines?: number
}

export default function LeadingProgress({lines}:LeadingProgressProps){
      return <React.Fragment>{
        Array.from({length: lines || 5}).map((_,idx)=><Skeleton key={idx}/>)}
        </React.Fragment>
}