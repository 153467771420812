import ErrorBox from 'src/components/ErrorBox'
import { Button } from '@mui/material'
import { useWidgetData } from 'src/lib/api'
import { NoticeBox, Spinner, Header,Card,CardRow,Container, TopNotice, CardValue } from 'src/components/Widget'
import dayjs from 'dayjs'
import React from 'react'
import { FetchDataError } from 'src/lib/errors'

const loginHandler = () =>{
    window.open("/auth", "_blank")
}

const openUploaderHandler = () =>{
    window.open("/","_blank","noopener,noreferrer")?.focus()
}

export default function WidgetScreen() {
    return (
    <Container>
        <Widget/>
    </Container>
    )
} 

function Widget() {
    const {data: widgetData, error, isLoading} = useWidgetData()
    if (error) {
        return error instanceof FetchDataError && error.errorJson.code === 401 ? (
            <React.Fragment>
                <p>Войдите в систему, что бы использовать виджет. </p>
                <div>
                    <Button variant='contained' type='button' children="Войти" onClick={loginHandler}/>
                </div>
            </React.Fragment>
        ): (

         <ErrorBox error={error}/>
        )
    }
    if (isLoading) {
        return <NoticeBox><Spinner/></NoticeBox>
    }
    const status = widgetData?.status || []
    const {newLeads, rejectedLeads} = widgetData?.totals || {}
    const {alarm, updatedAt} = widgetData || {}
    
    return (
        <div>
            <Header>Данные aктуальны на {updatedAt ?  `${dayjs(updatedAt).format('DD.MM.YYYY HH:mm:ss')}`: null}</Header> 
            {alarm ? <TopNotice onClick={openUploaderHandler} children={"Внимание! Требуется обновить данные с портала"}/>: null}
            <Card caption="Новых заявок с портала:">
                <CardValue>{newLeads}</CardValue>
            </Card>
            <Card caption="Отменено заявок:" >
                <CardValue>{rejectedLeads}</CardValue>
            </Card>
            <Card caption="Последнее успешное обновление:">
                {status.map(({caption, value, error},index)=><CardRow key={index} caption={caption} value={value} error={error}/> )}    
            </Card>
                
        </div>
    )   
}