
import {ErrorJson, ApiRes} from 'src/../../common/src/api'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import { ERR_REQUEST_NOT_SENT, ERR_UNKOWN, ERR_NO_RESPONSE, FetchDataError} from './errors'

export default async function fetchData<T = any, D = any>(config: AxiosRequestConfig<D>) {
    return axios.request<ApiRes<T>>({...config, withCredentials: true})
        .then((res) => res.data)
        .catch((axiosError: AxiosError<ApiRes<T>>)=>{
            //TODO do we need to log error to console? 

            //no request was sent (default error)
            let currentError : ErrorJson = ERR_REQUEST_NOT_SENT

            if (axiosError.response && 
                //BUG IN axios - will be fixed in next release
                axiosError.response.constructor.name !== 'XMLHttpRequest') {
                //got response
                currentError = axiosError.response.data.error || ERR_UNKOWN
            }else if (axiosError.request) {
                //request was sent but no response
                currentError = ERR_NO_RESPONSE
            }                    
            throw new FetchDataError(currentError)
        })
}