import * as React from 'react'
import AppBar from 'src/components/AppBar'
import { Toolbar, Box } from '@mui/material'
import {Outlet} from 'react-router-dom'

export default function AppLayout() {
    return (
        <React.Fragment>
            <AppBar/>
            <Box sx={{flex: 1, display: 'flex', flexFlow: 'column nowrap', overflow: 'auto'}}>
                <Toolbar/>
                <Outlet/>
            </Box>
        </React.Fragment>
    )
}