import * as React from 'react'
import {UserRoles} from 'src/../../common/src/api'
import { useAppInfo } from 'src/lib/api'

interface VisibleForProps {
    children: React.ReactNode
    roles?: UserRoles[]
    all?: boolean
}

export default function VisibleFor({children, roles, all}: VisibleForProps) {
    const role = useAppInfo().data?.user?.role
    return all || (roles && role && roles.includes(role)) ? 
        <React.Fragment>{children}</React.Fragment> 
        : null
}