import * as React from 'react'
import debounce from 'lodash/debounce'
import { 
    RiFileAddLine as NewIcon, 
    RiFileEditLine as UpdateIcon, 
    RiFileDamageFill as CancelIcon,
    //RiTimeLine as WaitingIcon
} from 'react-icons/ri'
import {
    BsCloudCheckFill as ProcessedIcon
} from 'react-icons/bs'
import {BiDotsHorizontal as WaitingIcon} from 'react-icons/bi'

import { LeadLogRecord, LeadLogReq } from 'src/../../common/src/api'
import { useLeadLog } from 'src/lib/api'

import { LeadEventTypes } from 'src/../../common/src/lead'

import LeadEventDetails from 'src/components/LeadEventDetails'

import { formatDateTimestamp, formatTimestamp, getStartOfDay, getEndOfDay } from 'src/lib/tools'
import { 
    Header, 
    DatePicker, 
    Toolbar, 
    ActionRefreshButton, 
    SearchField, 
    Infobar, 
    InfoField, 
    DisabledContext,
    Column,
    DataTable,
    CellText,
    ActionShowDetails,
    CellIcon,
} from './DataTable';
import useFormedAt from 'src/lib/useFormedAt'
import {Card, CardContent } from '@mui/material'

type LeadEventRow = LeadLogRecord & {
    _searchIndex: string,
    happenedAtVal: string,
    leadDate: string
}
type IconDicElement = {icon: React.ReactElement, color: any, title: string}
const typeIconsDic:  Record<LeadEventTypes,{icon: React.ReactElement, color: any, title: string}> = {
                [LeadEventTypes.NEW]:       {icon: <NewIcon/>,      color: 'success'   ,title:'Новая заявка' },
                [LeadEventTypes.UPDATE]:    {icon: <UpdateIcon/>,   color: 'warning'   ,title:'Изменение заявки' },
                [LeadEventTypes.CANCEL]:    {icon: <CancelIcon/>,   color: 'error'     ,title:'Отмена заявки' },
            }  
type ProcStatusTypes = 'success' | 'waiting'
const procIconDic: Record<ProcStatusTypes,IconDicElement> = {
    success: { icon: <ProcessedIcon/>, color: 'success', title: 'Успешно выгружено'}, 
    waiting: { icon: <WaitingIcon/>, color: 'default', title: 'В очереди к выгрузке'}
} 

const columns: Column<LeadEventRow>[]  = [
    {id: '_showDetails',                              cell: (props) => <ActionShowDetails {...props} />,    fixed: true,    width: 50},
    {id: 'type',           header: 'Тип'                        ,cell: ({row}) => <CellIcon val={row.type} iconsDic={typeIconsDic}/>,         fixed: true,    width: 50},
    {id: 'processed',      header: 'Выгружено в AmoCRM'         ,cell: ({row}) => <CellIcon val={row.processed ? 'success' : 'waiting'} iconsDic={procIconDic}/>, fixed: true, width: 50},
    {id: 'organization',   header: 'Организация'                ,cell: ({row}) => <CellText>{row.organization}</CellText>,             width: 200},
    {id: 'happenedAt',     header: 'Дата события'               ,cell: ({row}) => <CellText>{row.happenedAtVal}</CellText>,               width: 200},
    {id: 'eduDivision',    header: 'Портал'                     ,cell: ({row}) => <CellText>{row.eduDivision}</CellText>,              width: 100},
    {id: 'leadId',         header: 'Номер заявки'               ,cell: ({row}) => <CellText withTooltip children={row.leadId}/>,       width: 400},
    {id: 'leadDate',       header: 'Дата подачи заявки'         ,cell: ({row}) => <CellText withTooltip children={row.leadDate}/>,  width: 200},
    {id: 'studentName',    header: 'Слушатель'                  ,cell: ({row}) => <CellText withTooltip children={row.studentName}/>,  width: 400},
    {id: 'programName',    header: 'Название программы'         ,cell: ({row}) => <CellText withTooltip children={row.programName}/>,  width: 400}
]

const renderDetails  = (row: LeadEventRow) => <LeadEventDetails eventId={row._id}/>

const LeadLogRecordToLeadEventRow: (el: LeadLogRecord) =>LeadEventRow = el => ({...el, 
    happenedAtVal: formatTimestamp(el.happenedAt),  
    leadDate: formatDateTimestamp(el.leadDateTs),
            _searchIndex: String(`${typeIconsDic[el.type].title}
${procIconDic[el.processed ? 'success' : 'waiting'].title}
${el.studentName}
${el.leadId}
${el.eduDivision}
${formatDateTimestamp(el.leadDateTs)}
${el.programName}
${el.organization}`).toLowerCase()}) 

export default function LeadLogTable() {
    const [rows, setRows] = React.useState<LeadEventRow[]>([])
    const [periodStart, setPeriodStart] = React.useState<number>(getStartOfDay())
    const [periodEnd, setPeriodEnd] = React.useState<number>(getEndOfDay())
    const [leadLogReqParams, setLeadLogReqParams] = React.useState<LeadLogReq>({periodStart,periodEnd})
    const {data: leadLog, isLoading , error, mutate} = useLeadLog({params: leadLogReqParams})
    const [needRefresh, setNeedRefresh] = React.useState<boolean>(false)
    const formedAt = useFormedAt(leadLog)
    const [searchText,setSearchText] = React.useState<string>('')
    const [rowsData, setRowsData] = React.useState<LeadEventRow[]>([])

    React.useEffect(()=>{
        setRowsData((leadLog || []).map(LeadLogRecordToLeadEventRow))
    },[leadLog]) 

    const applySearchFilter = React.useMemo(() =>{
        return debounce(
            (val: string, leadEventRows: LeadEventRow[]) => {
                const searchVal = val.toLowerCase()
                setRows(val ? leadEventRows.filter(leadEvent => leadEvent._searchIndex.includes(searchVal)) : leadEventRows )
            }, 300)
    },[])

    const searchTextChangeHandler: (event: React.ChangeEvent<HTMLInputElement>)=> void  = (event) => {
        setSearchText(event.target.value)
    }

    React.useEffect(()=>{
        applySearchFilter(searchText,rowsData)
    }, [rowsData, searchText, applySearchFilter])

    const getRowId = React.useMemo(()=> (row: LeadEventRow) => row._id, [])
    const periodStartChangeHandler = (newValue: Date | null) => {
        setNeedRefresh(true)
        setPeriodStart(getStartOfDay(newValue))
    }
    const periodEndChangeHandler = (newValue: Date | null) => {
        setNeedRefresh(true)
        setPeriodEnd(getEndOfDay(newValue))
    }
    const refreshRows = React.useMemo(()=>() =>{
        setNeedRefresh(false)
        setLeadLogReqParams({periodStart, periodEnd})
        mutate()
    },[mutate,periodStart,periodEnd])

    return  (
        <Card sx={{flex: '1 1 auto', display: 'flex'}}>
            <CardContent sx={{flex: '1 1 auto',  display: 'flex', flexFlow: 'column nowrap', p: 2}}>
            <Header caption='Журнал изменений в заявках с портала'/>
            <DisabledContext.Provider value={isLoading}>
                <Toolbar>
                    <DatePicker start value={periodStart} onChange={periodStartChangeHandler}/> 
                    <DatePicker end value={periodEnd} onChange={periodEndChangeHandler}/>
                    <SearchField value={searchText} onChange={searchTextChangeHandler}/>
                    <ActionRefreshButton onClick={refreshRows}/>
                </Toolbar>
                <Infobar>
                    <InfoField caption='Данные актуальны на'    value={formedAt}            />
                    <InfoField caption='Количество строк'       value={String(rows.length)} />
                </Infobar>
            </DisabledContext.Provider>
            <div style={{flex: '1 1 auto'}}>
            <DataTable<LeadEventRow> {...{getRowId, error, columns, isLoading, needRefresh, refreshRows, rows, renderDetails }} />
            </div>
            </CardContent>
        </Card>
   )
}