/** event types */
export enum LeadEventTypes {
    NEW = "NEW",
    UPDATE = "UPDATE",
    CANCEL = "CANCEL"
}

/** разделы портала edu.rosminzdrav.ru*/
export enum EduDivisions {
    VO = "VO", //высшее образование
    SPO = "SPO"//среднее профессиональное образования
}
type EduPaymentOptionsTypes = 'tfoms' | 'legal' 

export const eduPaymentOptions: Record<EduPaymentOptionsTypes,string> = {
    tfoms : "Договорная (за счет средств ФФОМС/ТФОМС)",
    legal : "Договорная" 
} 
type EduPayerOptionTypes = 'company' | 'individual'

export const eduPayerOptions: Record<EduPayerOptionTypes, string> = {
    company: "Юридическое лицо",
    individual: "Физическое лицо"
}


export type LeadFields = {
    programId: string       //Номер программы
    programName: string     //Название программы
    zetAmount: number       //Трудоемкость, ак.ч (ЗЕТ)
    startDate: string       //Дата начала цикла
    endDate: string         //Дата окончания цикла
    studentName: string     //ФИО специалиста
    birthDate: string
    snils: string           //СНИЛС
    leadId: string          //Номер заявки
    leadDate: string        //Дата подачи заявки
    leadStatus: string      //Статус заявки
    studentRegion: string   //Регион специалиста
    studentEmployer: string //Место работы
    studentJobTitle: string //Должность
    leadPaymentOption: string //Основа обучения
    price: string           //Стоимость обучения
    payer: string           //Плательщик
    email: string           //E-mail
    phone: string           //Номер телефона
    additionalInfo: string  //Дополнительная информация, включая контакты плательщика
    comment: string         //Комментарий
}

/** структура заявки */
export type Lead =  Partial<LeadFields>
export type LeadFieldsProps = Record< keyof Lead, string>

export type LeadDiff = Partial<Record<keyof Lead,{
    oldValue: any,
    newValue: any
}>>
export const leadFieldsProps : LeadFieldsProps = {
    'programId'     : 'Номер программы',
    'programName'   : 'Название программы',
    'zetAmount'     : 'Трудоемкость, ак.ч (ЗЕТ)',
    'startDate'     : 'Дата начала цикла',
    'endDate'       : 'Дата окончания цикла',
    'studentName'   : 'ФИО специалиста',
    'snils'         : 'СНИЛС',
    'birthDate'     : 'Дата рождения',
    'leadId'        : 'Номер заявки',
    'leadDate'      : 'Дата подачи заявки',
    'leadStatus'    : 'Статус заявки',
    'studentRegion' : 'Регион специалиста',
    'studentEmployer': 'Место работы',
    'studentJobTitle': 'Должность',
    'leadPaymentOption': 'Основа обучения',
    'price'         : 'Стоимость обучения',
    'payer': 'Плательщик',
    'email': 'E-mail',
    'phone': 'Номер телефона',
    'additionalInfo': 'Дополнительная информация, включая контакты плательщика',
    'comment': 'Комментарий'

} 