import * as React from 'react'
import {ErrorJson, ApiRes} from 'src/../../common/src/api'
import fetchData  from './fetchData'
import { ERR_PARSING_RESPONSE, FetchDataError } from 'src/lib/errors'
import {AxiosRequestConfig} from 'axios'
import useNotice from './useNotice'

const DEFAULT_SUCCESS_MESSAGE = "Завершено"
interface ApiEndpointOptions {
    successMessage?: string
}
export default function useApiEndpoint<T = any, D = any> (endpoint: string, {successMessage = DEFAULT_SUCCESS_MESSAGE}: ApiEndpointOptions = {}) {

    const [isLoading, setIsLoading]     = React.useState(false)
    const [error, setError]             = React.useState<ErrorJson| undefined>()
    useNotice({error})
    const [success, setSuccess]         = React.useState< string | undefined>()
    const [delayed, setDelayed]         = React.useState(true)

    let timeout: NodeJS.Timeout | undefined

    const post: (data?: AxiosRequestConfig<D>)=>Promise<ApiRes<T>> = async (data = {}) => {
        
        setIsLoading(true)
        setDelayed(true)
        setSuccess(undefined)
        setError(undefined)

        timeout = setTimeout(()=>{
            setDelayed(false)},300) 
        try {
            const res = await fetchData<T>({method:'post',  url:`${process.env.REACT_APP_BACKEND_URL}${endpoint}`, ...data})
            if (res.success) {
                setError(undefined)
                setSuccess(successMessage)
            }else{
                setError(res.error)
            }
            return res
        }catch(currentError){
            const errorJson =  currentError instanceof FetchDataError ? currentError.errorJson : ERR_PARSING_RESPONSE
            setError(errorJson)
            return {success: false, error: errorJson}
        }finally{
            clearTimeout(timeout)
            setIsLoading(false)
        }
    }
    return {post, isLoading, error, success, delayed}
}