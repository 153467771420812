import { useDashboard } from 'src/lib/api'
import { Switch, Card, CardContent, Typography, Box, Tooltip } from '@mui/material'
import React from 'react'
import InfoField from './InfoField'
import useFormedAt from 'src/lib/useFormedAt'
import MutexField from './MutexField'
import { usePubSub } from './PubSubProvider'
import { REFETCH_DASHBOARD } from 'src/lib/constants'
import { formatTimestamp } from 'src/lib/tools'
interface InfoRowProps {
    children: React.ReactNode
}
const InfoRow = ({children}:InfoRowProps) => <Box sx={{display: 'flex', flexFlow: 'row', flexWrap: 'wrap', rowGap:2, columnGap: 4, my: 2 }}>{children}</Box>

export default function Dashboard() {
    const pubSub = usePubSub()
    const [realtime, setRealtime ] = React.useState<boolean>(true)
    const {data: dashboardData, mutate } = useDashboard({refreshInterval: realtime ? 1000 : undefined})
    const formedAt = useFormedAt([dashboardData])

    const {upload, leadProcessing, amoIntegrationInfo } = dashboardData || {} 
    React.useEffect(()=>{
        const handler = async () =>{
            await mutate()
        }
        pubSub?.subscribe(REFETCH_DASHBOARD , handler)
        return ()=>{
            pubSub?.unsubscribe(REFETCH_DASHBOARD, handler)
        }
    }, [mutate, pubSub])

    const setRealtimeHandler = () => {
        setRealtime(val => (!val))
    }
    return (
                <Card>
                    <CardContent>
                        <Box sx={{display: 'flex', flexFlow: 'row', gap: 1, justifyContent: 'space-between'}}>
                            <Typography gutterBottom variant='h5'>
                                Мониторинг 
                            </Typography> 
                            <Box sx={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center',  }}>
                                <span>
                                    <Typography variant='caption' color={'text.secondary'} sx={{mr: 1}}>
                                        Обновлено 
                                    </Typography>
                                    <Typography variant='caption' sx={{flexShrink:0,whiteSpace: 'nowrap' }} >
                                        {formedAt}
                                    </Typography>
                                </span>
                                <Tooltip title="Автообновление">
                                    <Switch checked={realtime} onChange={setRealtimeHandler}/>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Typography variant='h6'>Обмен данными с amoCRM</Typography>
                        <InfoRow>
                            <MutexField  caption= 'Статус обиена данными' mutex={leadProcessing?.mutex} threshold='5s'/>
                            <InfoField variant={'big'} caption='Сообщений к отправке' 
                                    alarm={leadProcessing ? leadProcessing.queueLength > 0 ? 'yellow' : 'green' : undefined}
                                    value={leadProcessing?.queueLength}/>
                            <InfoField variant={'big'} caption='Ошибок обмена' 
                                    alarm={leadProcessing ? leadProcessing.service.errorsCount > 0 ? 'red' : 'green': undefined } 
                                    value={leadProcessing?.service.errorsCount}/>
                            <InfoField  caption='Интервал между попытками' value={leadProcessing ? leadProcessing.service.currentDelay / 1000 : undefined }/>
                        </InfoRow>
                        <InfoRow>
                            <InfoField caption='Статус интеграции'value={amoIntegrationInfo?.registered ? 'зарегистрирована': 'не зарегистрирована'}/>
                            <InfoField caption='Аккаунт amoCRM' value={amoIntegrationInfo?.account}/>
                            <InfoField caption='Токен получен' value={formatTimestamp(amoIntegrationInfo?.token?.createdAt)}/>
                            <InfoField caption='Токен действителен до' value={formatTimestamp(amoIntegrationInfo?.token?.expiresAt)}/>
                            <InfoField caption='Последнее обращение' value={formatTimestamp(amoIntegrationInfo?.lastUsedAt)}/>
                        </InfoRow>
                        <Typography variant='h6'>Загрузка файлов</Typography>
                            <MutexField  caption= 'Cтатус загрузки файлов' mutex={upload} threshold='1m'/>
                        <InfoRow>
                        </InfoRow>
                    </CardContent>
                </Card>
    )
}