import * as React from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import useApiEndpoint from 'src/lib/useApiEndpoint'
import useNotice from 'src/lib/useNotice'
import { LocationState } from 'src/components/AuthUsersOnly'
import { API_LOGIN } from 'src/../../common/src/api'
import {LoadingButton } from '@mui/lab'
import {Box, TextField } from '@mui/material'

import { useAppInfo } from 'src/lib/api'

export function LoginScreen(props: any){
    /** hooks */
    const {data: appInfo, mutate, isLoading: isAppInfoLoading} = useAppInfo()
    const location = useLocation()
    const navigate = useNavigate()
    const [login, setLogin] = React.useState('')
    const [password, setPassword] = React.useState('')
    const {post, error, isLoading: isLogInLoading, delayed} = useApiEndpoint(API_LOGIN)

    const isLoading = isAppInfoLoading || isLogInLoading
    useNotice({error})

    const from  = (location.state as LocationState)?.from.pathname || '/app'
    if (appInfo?.user) {
        return <Navigate to={from} replace={true}/>
    }

    const loginHandler = async () => {
            const res = await post({data:{login, password}})
            if (res.success){
                navigate(from,{replace: true})
                mutate(undefined)
            }
    }

    const keyDownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
        event.key === 'Enter' && loginHandler()
    }
    
    return (
        <React.Fragment>
            <Box sx={{display: 'flex', alignItems: 'center', flexFlow: 'column nowrap', maxWidth: 300, gap: 2, mx: 'auto', mt: 6}} >
                <TextField size='small' fullWidth disabled={isLoading} onKeyDown={keyDownHandler} type="text"     value={login}    label="Логин"  onChange={event => setLogin(event.target.value)}/>
                <TextField size='small' fullWidth disabled={isLoading} onKeyDown={keyDownHandler} type="password" value={password} label="Пароль" onChange={event => setPassword(event.target.value)}/>
                <LoadingButton variant='contained' loading={isLoading && !delayed} disabled={isLoading} children="Войти" onClick={loginHandler}/>
            </Box>
        </React.Fragment>
    )
}