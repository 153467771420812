import { Typography } from '@mui/material'
import * as React from 'react'


export default function WidgetStatus() {
    return <React.Fragment>
        <Typography gutterBottom variant='h4'>
            Авторизация пройдена успешно!
        </Typography>
        <Typography  variant='body1'>
            Теперь вы можете пользоваться виджетом на рабочем столе AmoCRM
        </Typography>
    </React.Fragment>
}