import { ErrorJson } from 'src/../../common/src/api';
import * as React from 'react'
import { useSnackbar } from 'notistack';

interface NoticeProps {
    error?: ErrorJson | Error 
    success?: string 
    info?: string
    message?: string
}

export default function useNotice({error, success, info, message}: NoticeProps) {
    const {enqueueSnackbar} = useSnackbar()
    React.useEffect(()=>{
        error   && enqueueSnackbar(error.message, {variant: 'error'}) 
        success && enqueueSnackbar(success, {variant: 'success'}) 
        info    && enqueueSnackbar(success, {variant: 'info'}) 
        message && enqueueSnackbar(message, {variant: 'default'})
    }, [error, success, info, message, enqueueSnackbar])  
}
