import { FileParsingResult, UploadLogRecord, UploadLogReq } from 'src/../../common/src/api'
import { Card, CardContent} from '@mui/material'
import * as React from 'react'
import { useUploadLog } from 'src/lib/api'
import { CellIcon, ActionRefreshButton, CellText, Column, DataTable, DatePicker, DisabledContext, Header, Infobar, InfoField, Toolbar } from './DataTable'
import { formatTimestamp, getStartOfDay, getEndOfDay, getStartOfDayAgo } from 'src/lib/tools'
import useFormedAt from 'src/lib/useFormedAt'

import {
    Done as SuccessIcon, 
    Error as ErrorIcon,
    FastForward as SkipIcon
} from '@mui/icons-material'
import { usePubSub } from './PubSubProvider'
import { REFETCH_UPLOAD_LOG } from 'src/lib/constants'

const parsingIconsDic:  Record<FileParsingResult,{icon: React.ReactElement, color: any, title: string}> = {
                [FileParsingResult.success]:        {icon: <SuccessIcon/>,      color: 'success'   ,title:'Успех' },
                [FileParsingResult.skip]:           {icon: <SkipIcon/>,         color: 'warning'   ,title:'Пропущен' },
                [FileParsingResult.error]:          {icon: <ErrorIcon/>,        color: 'error'     ,title:'Ошибка' },
            }  
type UploadLogRow = {
    _id: string
    parsing?: FileParsingResult
    happenedAt: string 
    userName: string
    fileName: string
    fileSize: string
    fileHash: string
    totalRows?: number 
    newLeads?: number
    updatedLeads?: number
    canceledLeads?: number
}
function recordToRow({_id, fileName, userName, happenedAt, stats, fileHash, fileSize, parsing}: UploadLogRecord): UploadLogRow {
    return {
        _id,
        fileName,
        userName,
        fileHash,
        fileSize: String(fileSize),
        happenedAt: formatTimestamp(happenedAt),
        totalRows: stats?.totalRows,
        newLeads: stats?.eventTypes.NEW,
        updatedLeads: stats?.eventTypes.UPDATE,
        canceledLeads: stats?.eventTypes.CANCEL,
        parsing
    }
}

const columns: Column<UploadLogRow>[] = [
    {id: 'happenedAt',          header: 'Дата и время',     cell: ({row})=><CellText>{row.happenedAt}</CellText> , width: 200, fixed: true},
    {id: 'result',              header: 'Результат обработки файла',            cell: ({row})=><CellIcon val={row.parsing} iconsDic={parsingIconsDic}/>, width: 50, fixed: true},
    {id: 'userName',            header: 'Пользователь',     cell: ({row})=><CellText>{row.userName}</CellText>, width: 200 },
    {id: 'fileName',            header: 'Имя файла',        cell: ({row})=><CellText withTooltip>{row.fileName}</CellText>, width: 400 },
    {id: 'totalRows',           header: 'Всего строк',      cell: ({row})=><CellText>{row.totalRows}</CellText>, width: 150, fixed: true},
    {id: 'newLeads',            header: 'Новых',            cell: ({row})=><CellText>{row.newLeads}</CellText>, width: 150, fixed: true},
    {id: 'updatedLeads',        header: 'Изменено',         cell: ({row})=><CellText>{row.updatedLeads}</CellText>, width: 150, fixed: true},
    {id: 'canceledLeads',       header: 'Отменено',         cell: ({row})=><CellText>{row.canceledLeads}</CellText>, width: 150, fixed: true},
    {id: 'fileHash',            header: 'Хэш',              cell: ({row})=><CellText>{row.fileHash}</CellText>, width: 400 },
    {id: 'fileSize',            header: 'Размер',           cell: ({row})=><CellText>{row.fileSize}</CellText>, width: 400 },

]

export default function UploadLogTable() {
    const pubSub = usePubSub()
    const [needRefresh, setNeedRefresh] = React.useState<boolean>(false)
    const [periodStart, setPeriodStart] = React.useState<number>(getStartOfDayAgo(30))
    const [periodEnd, setPeriodEnd] = React.useState<number>(getEndOfDay())
    const [reqParams, setReqParams] = React.useState<UploadLogReq>({periodStart,periodEnd})
    const {data, error, mutate, isLoading} = useUploadLog({params: reqParams})
    const formedAt = useFormedAt(data)
    const [rows,setRows] = React.useState<UploadLogRow[]>([])

    React.useEffect(()=>{
        setRows((data || []).map(el=>recordToRow(el)))
    },[data])

    React.useEffect(()=>{
        const handler = async () =>{
            await mutate()
        }
        pubSub?.subscribe(REFETCH_UPLOAD_LOG, handler)
        return ()=>{
            pubSub?.unsubscribe(REFETCH_UPLOAD_LOG, handler)
        }
    }, [mutate, pubSub])

    const getRowId = React.useMemo(()=>(row: UploadLogRow)=>row._id, [])
    const periodStartChangeHandler = (newValue: Date | null) => {
        setNeedRefresh(true)
        setPeriodStart(getStartOfDay(newValue))
    }
    const periodEndChangeHandler = (newValue: Date | null) => {
        setNeedRefresh(true)
        setPeriodEnd(getEndOfDay(newValue))
    }
    const refreshRows = () =>{
        setNeedRefresh(false)
        setReqParams({periodStart, periodEnd})
        mutate(undefined)
    }

    return (
        <Card>
            <CardContent>
                <DisabledContext.Provider value={isLoading}>
                    <Header caption='Журнал загрузки'/>
                    <Toolbar>
                        <DatePicker start value={periodStart} onChange={periodStartChangeHandler}/> 
                        <DatePicker end value={periodEnd} onChange={periodEndChangeHandler}/>
                        <ActionRefreshButton onClick={refreshRows}/>
                    </Toolbar>                 
                    <Infobar>
                        <InfoField caption='Данные актуальны на'    value={formedAt}            />
                        <InfoField caption='Количество строк'       value={String(rows.length)} />
                    </Infobar>
                </DisabledContext.Provider> 
                <div style={{height: 400}}>
                    <DataTable<UploadLogRow> {...{getRowId, error, isLoading, columns, needRefresh, refreshRows, rows }}/>
                </div>
            </CardContent>
        </Card>
    )
}