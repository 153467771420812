import * as React from 'react'
import { SnackbarKey, SnackbarProvider } from "notistack";
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export interface NoticeProviderProps {
    children: React.ReactNode 
}

export default function NoticeProvider({children}:NoticeProviderProps) {
    const snackRef = React.createRef<SnackbarProvider>() 

    const createDismissHandler = (key:SnackbarKey) => () =>{
        snackRef?.current?.closeSnackbar(key)
    }
    return(
        <SnackbarProvider
            maxSnack={10}
            ref = {snackRef}
            action = {(key)=>(
                <IconButton onClick={createDismissHandler(key)}>
                    <Close/>
                </IconButton>
            )} 
            children={children}/>
    ) 

}