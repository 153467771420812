import * as React from 'react'
import logo from 'src/logo.svg'
import { useAppInfo } from 'src/lib/api'
import { API_LOGOUT } from 'src/../../common/src/api'
import useApiEndpoint from 'src/lib/useApiEndpoint'
import useNotice from 'src/lib/useNotice'
import {Button, Box, AppBar, Toolbar, CircularProgress, Menu, MenuItem, ListItemIcon, ListItemText} from '@mui/material'
import {AccountCircle, Logout} from '@mui/icons-material'

export default function MyAppBar (){
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const {data: appInfo, isLoading: isLoadingAppInfo, error, mutate: mutateApiInfo} = useAppInfo()
    const {post: logout, isLoading: isLoadingLogout, error: logoutError } = useApiEndpoint(API_LOGOUT)
    useNotice({error: logoutError})

    const isLoading = isLoadingLogout || isLoadingAppInfo
    const open = !!anchorEl

    const performLogout = async () => {
        const res = await logout()
        res.success && mutateApiInfo(undefined)
    }  
    const logoutHandler = () =>{
        setAnchorEl(null)
        performLogout()
    }
    const openUserMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const closeUserMenuHandler = () => {
        setAnchorEl(null)
    }
    let  toolbarActions: JSX.Element | null = null

    if (isLoading) {
        toolbarActions = <CircularProgress color='inherit'/>

    }else if(!error) {
        toolbarActions = appInfo?.user ? (
            <React.Fragment>
                <Button onClick={openUserMenuHandler} 
                    id="user-menu-button"
                    color='inherit' 
                    aria-controls={open ? 'user-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{ textTransform: 'none', flexShrink: 0}}>
                        <AccountCircle sx={{mr: 1}}/>
                        {appInfo?.user.name}
                </Button>
                <Menu open={open}
                    id='user-menu'
                    aria-labelledby="user-menu-button"
                    anchorEl={anchorEl} 
                    onClose={closeUserMenuHandler}>
                        <MenuItem onClick={logoutHandler}>
                            <ListItemIcon>
                                <Logout fontSize='small'/>
                            </ListItemIcon> 
                            <ListItemText>Выход</ListItemText>
                            </MenuItem>
                </Menu>
            </React.Fragment>
        ) : null
    }
    return (
    <AppBar>
        <Toolbar>
            <Box component={'img'} sx={{height: 40}} src={logo}/>
            <Box sx={{ flexGrow: 1 }}></Box>
            {toolbarActions}
        </Toolbar>
    </AppBar>
    )
}