import * as React from 'react'
import  {
    Alert,
    Typography,
    TextField,
    Box,
    Skeleton,
    Tooltip, 
    IconButton,
    SvgIcon,

} from '@mui/material'

import { 
    ArrowRight as ExpandIcon, 
    ArrowDropDown as CollapseIcon ,
    Refresh as RefreshIcon
} from "@mui/icons-material";

import AutoSize from 'react-virtualized-auto-sizer'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers'
import { LoadingButton } from '@mui/lab';
import {styled} from '@mui/material/styles'
import {  VariableSizeList as List } from 'react-window'
import InfoFieldComp from './InfoField';
export const DisabledContext = React.createContext<boolean>(false)

/** Header */
interface HeaderProps {
    caption: string 
}
export function Header({caption}: HeaderProps) {
    return (
        <Typography variant="h5" gutterBottom>
        {caption}
        </Typography>
    )

}

/** Date picker */
interface DatePickerProps {
    start?: boolean
    end?: boolean
    label?: string
    value: number
    onChange: (newValue: Date | null) => void
}
export function DatePicker({ label, start, end, ...props} :DatePickerProps) {
    const disabled =React.useContext(DisabledContext)
    let labelText = label ? label : start ? 'Начало периода' : end ? 'Конец периода' : ''
    return (
       <MuiDatePicker label={labelText} {...props} disabled={disabled} renderInput={
            (params) => <TextField sx={{ flexShrink: 0}} {...params} size='small' />
        }/> 
    )
}

/** Toolbar */
export function Toolbar({children}:{children: React.ReactNode}) {
    return (
        <Box sx={{display: 'flex', flexFlow: 'row', flexWrap: 'wrap', columnGap: 2, rowGap: 2, mb:2 }}>
            {children}
        </Box>
    )
}
/** InfoBar */
export function Infobar({children}: {children: React.ReactNode}) {
    return (
        <Box sx={{display:'flex', justifyContent: 'flex-end', flexFlow: 'row nowrap', mb: 2,gap: 4 }}>
            {children}
        </Box>
    )
}

/** InfoField */
interface InfoFieldProps {
    caption: string
    value: string
}
export function InfoField({caption, value}: InfoFieldProps) {
    const disabled =React.useContext(DisabledContext)
    return (
        <InfoFieldComp {...{caption, value, disabled}}/>
    )
}

/** Refetch action button */
interface RefreshButtonProps {
   onClick: () => void 
}

export function ActionRefreshButton(props: RefreshButtonProps) {
    const disabled =React.useContext(DisabledContext)
    return (
        <LoadingButton disabled={disabled} {...props}  sx={{ flexShrink: 0}} startIcon={<RefreshIcon/>}>Обновить</LoadingButton>
    )
}
/**CellIcon */
interface CellIconProps<T extends string | number | symbol> {
    val?: T,
    iconsDic: Record<T, {icon: React.ReactElement, color: any, title: string}> 
}

export const CellIcon = <T extends string | number | symbol,>({val, iconsDic}:CellIconProps<T>) => {
    const iconData = val && iconsDic[val]            
    return iconData ? (
        <CellIcons>
            <Tooltip arrow title={iconData.title}>
                <SvgIcon color={iconData.color} sx={{verticalAlign: 'middle'}}>
                    {iconData.icon}
                </SvgIcon>
            </Tooltip>
        </CellIcons>
    ) : null
}
/** StateSet */
export function useStateSet<T>(): [ (val: T)=>boolean, (val:T)=>void, ()=>void] {
    const [stateSet, setStateSet] = React.useState<Set<T>>(new Set())

    const hasValue =    React.useMemo(()=>(val: T) => stateSet.has(val), [stateSet])
    const resetSet =    React.useMemo(()=> () => setStateSet(new Set()),[])
    const toggleValue = React.useMemo(()=>(val: T) => {
                setStateSet(old => {
                    const res  = new Set(old)
                    res.has(val) ? res.delete(val) : res.add(val)
                    return res
                })
    },[])
    return [hasValue, toggleValue, resetSet]
}
/** Search field */
type InputChangeEventHandler =  (newValue: React.ChangeEvent<HTMLInputElement>) => void
export function useDelayedSearch(applySearchFilter:(newValue: string)=> void): [string, InputChangeEventHandler] {
    const [searchText, setSearchText] = React.useState<string>('')
    const searchTimeoutRef = React.useRef<number | undefined>()

    const clearSearchTimeout = () => {
        if (searchTimeoutRef.current !== undefined) {
            window.clearTimeout(searchTimeoutRef.current)
            searchTimeoutRef.current = undefined
        }
    }
    
    const onChange : InputChangeEventHandler = (event) => {
        clearSearchTimeout()
        setSearchText(event.target.value)
        searchTimeoutRef.current = window.setTimeout(()=>{applySearchFilter(event.target.value)}, 300)
    }
    return [searchText, onChange]

}
interface SearchFieldProps {
    value: string
    onChange: (newValue: React.ChangeEvent<HTMLInputElement>) => void
}
export function SearchField ({value, onChange}: SearchFieldProps){
    const disabled =React.useContext(DisabledContext)
    return (
        <TextField sx={{flexGrow: 2}}
            {...{value,onChange,disabled}}
            size="small" 
            label="Поиск" 
            />
    )

}
/** CellText */
export const CellText = ({children, withTooltip, tooltip}:{children: React.ReactNode, withTooltip?: boolean, tooltip?: React.ReactNode} ) => 
    <CellTextInner>
        {(withTooltip || tooltip) ? 
        <Tooltip arrow title={tooltip ? tooltip || '' : children || ''}>
            <span>{children}</span>
        </Tooltip> : children }
    </CellTextInner>

const CellTextInner = styled('div')({
    padding: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}) 
/** CellIcons */
const CellIcons = styled('div')({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'

})

/** CellActions */
export const CellActions = styled(CellIcons)({
    cursor: 'pointer',
}) 
/** ActionShowDetails */
export const ActionShowDetails = ({row, detailsToggle, rowDetailsVisible}:CellRenderProps) => 
        <CellActions>
            <IconButton  onClick={()=>{detailsToggle && detailsToggle(row)}}>
                    {rowDetailsVisible ? <CollapseIcon/>  : <ExpandIcon/>}
            </IconButton>
        </CellActions>

/** DataTable */
interface DataTableProps<T, I>{
    //gridRef: React.RefObject<List<T>>
    isLoading: boolean
    needRefresh: boolean
    error?: Error 
    columns: Column[]
    rows: T[]
    getRowId: (row: T) => I
    refreshRows: ()=> void
    //rowDetailsVisible?: (row:T) => boolean
    //detailsToggle?: (row:T) => void
    renderDetails?: (row: T) => React.ReactNode
}

type ColumnSizes = Record<string, {width: number, offset: number}> 

interface ListSizeContext {
    columnSizes: ColumnSizes 
    innerWidth: number
    width: number
    height: number
}
interface CellRenderProps<T = any> {
    row: T
    rowDetailsVisible?: boolean
    detailsToggle?: (row : T) => void
}

export interface Column<T = any> {
    id: string
    cell?: (props: CellRenderProps<T>) => JSX.Element 
    header?: string
    width: number 
    fixed?: boolean
}

interface GridProps {
    columns: Column<any>[]
    style?: React.CSSProperties 
    children: React.ReactNode,
    height: number,
    width: number,
}

const listSizeContext = React.createContext<ListSizeContext>({columnSizes: {}, width: 100, height:100 , innerWidth: 100})
const columnsContext = React.createContext<Column[]>([])

const ROW_HEIGHT = 50 
const HEADER_ROW_HEIGHT = 50
const HEADER_ROWS_COUNT = 1
const HEADER_TOTAL_HEIGHT = HEADER_ROWS_COUNT * HEADER_ROW_HEIGHT
const DETAILS_HEIGHT = 400
const DEFAULT_COLUMN_WIDTH = 50

const getColumnWidths = ( width: number, cols: Column[]): [ColumnSizes, number] => {
    const res: ColumnSizes = {}

    if ( width === 0)  {
        return [res, 0]
    }

    let staticWidth = 0
    let dynamicWidth = 0 

    for (let col of cols) {
        const colDefWidth = (col.width || DEFAULT_COLUMN_WIDTH)
        if (col.fixed) {
            staticWidth += colDefWidth
        }else{
            dynamicWidth += colDefWidth
        }
    }

    const totalWidth = staticWidth + dynamicWidth
    const tooSmall = width < totalWidth 

    let widthLeft = width 
    let offset = 0

    for (let col of cols) {
        const colDefWidth = (col.width || DEFAULT_COLUMN_WIDTH)
        const colWidth = col.fixed || tooSmall ? colDefWidth : Math.min(Math.max(Math.ceil(((colDefWidth / dynamicWidth) * (width - staticWidth) )), colDefWidth), widthLeft)
        res[col.id] = {width: colWidth, offset}
        offset += colWidth 
        widthLeft -= colWidth
    }

    return [res, offset]
}


const Grid = ({children, style, width, height, columns}:GridProps) =>{
    const listSizeContextValue: ListSizeContext = React.useMemo(()=>{
       const [columnSizes, innerWidth] = getColumnWidths(width,columns)
        return {
        columnSizes,
        innerWidth,
        width,
        height
    }},[width,height,columns])
     return  (
        <listSizeContext.Provider value={listSizeContextValue}>
            <GridWrapper style={{...style, width, height}}>
                {children}
            </GridWrapper>
        </listSizeContext.Provider>
    )
}

const GridWrapper = styled('div')(({theme}) => ({
    border: '1px solid',
    borderColor: theme.controls.borderColor(theme) ,
}))

const Backdrop = styled(GridWrapper)({
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 5,
    backgroundColor: '#ffffffc5'
})
const HeaderTooltip = styled(Tooltip)({
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

})
const HeaderCell = styled('div')(({theme})=> ({
    position: 'absolute',
    borderBottom: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.controls.borderColor(theme) ,
    fontWeight: 'bold',
    height: '100%',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
}))

const innerElementType = React.forwardRef<HTMLDivElement,React.HTMLAttributes<HTMLDivElement>>(({children, style , ...rest}, ref)=>{
    const {columnSizes} = React.useContext(listSizeContext)
    const columns = React.useContext(columnsContext)
    const headersTotalHeight = HEADER_TOTAL_HEIGHT 
    return <div ref={ref} {...rest} style={{...style , height: `${parseFloat(String(style?.height) || '0') + headersTotalHeight}px`}}> 
            <div style={{position: 'sticky', zIndex: 3, left: 0, top: 0}}>
                {columns.map(header => (
                    <HeaderCell key={header.id}  
                        style={{
                            left: columnSizes[header.id].offset || 0,
                            top: 0,
                            height: HEADER_ROW_HEIGHT, 
                            width: columnSizes[header.id].width || DEFAULT_COLUMN_WIDTH
                        }}>
                        <HeaderTooltip arrow placement='top' title={header.header || ''}>
                            <span style={{margin: 16}}>{header.header}</span>
                        </HeaderTooltip> 
                    </HeaderCell>
                ))}
            </div>
            {children}     
    </div>

})

const SkeletonRows = ()=> {
    const {columnSizes, height, innerWidth} = React.useContext(listSizeContext)
    const columns = React.useContext(columnsContext)
    const length = Math.ceil(height / ROW_HEIGHT)
    return <React.Fragment>
        {Array.from({length}).map((el,idx)=> 
            <div key={idx} style={{display: 'flex', flexFlow: 'row nowrap', width:innerWidth}}>
                {columns.map(column => (<div key={column.id} style={{padding: '0 4px ', height: ROW_HEIGHT, width: columnSizes[column.id].width}}>
                    <Skeleton height={ROW_HEIGHT}/>  
                </div>
                ))}
            </div>
        )}
    </React.Fragment>
}

const Cell = styled('div')(({theme})=> ({
    position: 'absolute',
    height: ROW_HEIGHT,
    overflow: 'hidden',
    borderBottom: '1px solid',
    borderRight: '1px solid',
    borderColor: theme.controls.borderColor(theme) ,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
}))

interface TableRowProps<T> {
    row: T
    style: React.CSSProperties
    rowDetailsVisible: boolean
    detailsToggle?: (row : T) => void
    renderDetails?: (row: T) => React.ReactNode
}

function TableRow<T> ({rowDetailsVisible, detailsToggle, row, style, renderDetails }: TableRowProps<T>) {
    const {columnSizes} = React.useContext(listSizeContext)
    const columns = React.useContext(columnsContext)
    return <div style={{...style, top: parseFloat(String(style?.top) || '0') + HEADER_TOTAL_HEIGHT}}>
        {columns.map(col => (
            <Cell key={col.id} style={{
                top: 0, 
                left:  columnSizes[col.id].offset || 0 ,
                width: columnSizes[col.id].width,  
                height: ROW_HEIGHT }}>
                {col.cell &&  React.createElement(col.cell, {row, rowDetailsVisible, detailsToggle})}
            </Cell>
        ))}
        {renderDetails && rowDetailsVisible ? <Details>{renderDetails(row)}</Details> : null}
    </div>
}
interface DetailsProps {
    children: React.ReactNode
}
const DetailsContainer = styled('div')(({theme}) => ({
    position: 'absolute',
    borderBottom: '1px solid',
    borderColor: theme.controls.borderColor(theme) ,
    padding: ' 16px 16px 16px 50px'
}))

const Details = ({children}: DetailsProps) =>{
    const {innerWidth: width} = React.useContext(listSizeContext)
    return <DetailsContainer style={{position: 'absolute', top: ROW_HEIGHT, width , height: DETAILS_HEIGHT, zIndex: 2}}>
        {children}
    </DetailsContainer>
}
const genericMemo: <T>(component: T) => T = React.memo
function DataTableComp<T,I= number | string>({columns, needRefresh, refreshRows, error, isLoading, rows, renderDetails, getRowId}:DataTableProps<T,I>) {
//export const DataTable = React.memo<React.FunctionComponent<DataTableProps<T,I>>>(<T,I = number | string>({columns, needRefresh, refreshHandler, error, isLoading, rows, renderDetails, getRowId}:DataTableProps<T,I>) =>{
    const [isDetailsVisible, toggleDetailsVisibility, resetDetailsVisibility] = useStateSet<I>()
    const gridRef = React.createRef<List<T>>()

    React.useEffect(()=>{
        gridRef.current?.resetAfterIndex(0)
        rows.length > 0 && gridRef.current?.scrollToItem(0)
    //eslint-disable-next-line
    },[rows, gridRef.current])

    const rowDetailsVisible = (row: T) => isDetailsVisible(getRowId(row))

    const detailsToggle = (row : T) => {
                toggleDetailsVisibility(getRowId(row))
                gridRef.current?.resetAfterIndex( rows.indexOf(row)) 
            }
    const refreshHandler = () => {
       refreshRows && refreshRows() 
       resetDetailsVisibility()
    }
    let backdrop: React.ReactNode = undefined 

    if (needRefresh) {
        backdrop = <Alert severity='warning' 
            action={
            <LoadingButton size='small' 
                onClick={refreshHandler} 
                color='warning' variant='outlined' 
                startIcon={<RefreshIcon/>}>Применить</LoadingButton>}>
            Насторойки отбора изменены  Чтобы увидеть актуальные данные необходимо применить новые настройки 
        </Alert> 
    }else if (error) {
        backdrop = <Alert severity='error'>{error.message}</Alert>
    }
    return (
        <columnsContext.Provider value={columns}>
            <AutoSize>
                {({width, height}) =>  
                        isLoading  ? 
                            <Grid {...{width, height, columns, style: {overflowY: 'hidden'}}}>
                                {React.createElement(innerElementType,{children: <SkeletonRows/>}) }
                            </Grid> :
                            <Grid {...{width, height,columns}}>
                                <List<T>
                                    innerElementType={innerElementType}
                                    ref={gridRef}    
                                    itemCount={rows.length}
                                    itemSize={index => rowDetailsVisible && rowDetailsVisible(rows[index]) ? ROW_HEIGHT + DETAILS_HEIGHT : ROW_HEIGHT} 
                                    height={height}
                                    width={width}
                                    overscanCount={5}
                                    >
                                        {({style, index})=>{
                                            const row = rows[index]
                                            const detailsVisible = rowDetailsVisible ? rowDetailsVisible(row) : false
                                            return <TableRow {...{style, row, detailsToggle, renderDetails, rowDetailsVisible: detailsVisible}} />
                                        }}
                                </List>
                                { backdrop && <Backdrop>{backdrop}</Backdrop>}
                            </Grid>
                    }
            </AutoSize> 
        </columnsContext.Provider>
    )
}

export const DataTable = genericMemo(DataTableComp)