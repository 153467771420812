import * as React from 'react'

type PubSubEventHandler = () => void

interface PubSubValue {
    _list: Map<string, PubSubEventHandler[]>
    subscribe: (event: string, handler: PubSubEventHandler) => void
    emit: (event: string) => void
    unsubscribe: (event: string, handler: PubSubEventHandler) => void
    clear: () => void
}

const PubSubContext = React.createContext<PubSubValue | undefined>(undefined)

interface PubSubProviderProps {
    children: React.ReactNode
}

export default function PubSubProvider({children}: PubSubProviderProps) {
    const [pubSubValue, setPubSubValue]  = React.useState<PubSubValue | undefined>()
    React.useEffect(()=>{

        const val: PubSubValue = {
            _list: new Map<string, PubSubEventHandler[]>(),
            subscribe(event, handler) {
                this._list.has(event) || this._list.set(event,[])
                this._list.get(event)?.push(handler)
            },
            emit(event) {
                const arr = this._list.get(event)
                if (arr) {
                    for (const handler of arr) {
                    handler() 
                    }
                }
            },
            unsubscribe(event, handler) {
                const arr = this._list.get(event) 
                if (arr) {
                    this._list.set(event, arr.filter(el=> el !==handler))
                } 
            },
            clear(){
                this._list.clear()
            }
        }
        setPubSubValue(val)

        return () => {
            val.clear()
        }
    },[])

    return (
        <PubSubContext.Provider value={pubSubValue} >
            {children}
        </PubSubContext.Provider>
    )
}

export function usePubSub() {
    return React.useContext(PubSubContext)
}