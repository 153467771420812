import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import React from "react";
import useApiEndpoint from "src/lib/useApiEndpoint";
import {useForm, Controller, Control} from 'react-hook-form'

import { 
    API_AMO_REGISTER ,
    AmoRegisterNewIntegrationReq
} from  'src/../../common/src/api'
interface FormFieldProps {
    control: Control<AmoRegisterNewIntegrationReq>
    name: keyof AmoRegisterNewIntegrationReq
    label: string
    required?: boolean 
    placeholder? : string
}
const FormTextField = ({placeholder, label,control, name, required}:FormFieldProps) => 
                        <Controller name={name} control={control}  rules={{required: required ?'поле обязательно для заполнения': undefined}} render={
                            ({field:{onChange, value}, fieldState: {error}}) =>
                            <TextField placeholder={placeholder} fullWidth variant="standard" value={value} onChange={onChange} label={label} error={!!error} helperText={error && error.message}/>
                        }/>

export default function RegisterNewIntegrationButton() {
    const {handleSubmit, control, reset} = useForm<AmoRegisterNewIntegrationReq>({defaultValues: {url: '', clientId: '', clientSecret:'', code:'', redirectUrl:'', }})
    const {post} = useApiEndpoint<AmoRegisterNewIntegrationReq>(API_AMO_REGISTER)
    const [open,setOpen] = React.useState<boolean>(false)

    const openHandler = () =>{
        setOpen(true)
    }
    const closeHandler = () => {
        setOpen(false)
        reset()
    }
    const registerHandler = async (data: Record<string,any>) => {
        const res = await post({data})
        if (res.success) {
            setOpen(false)
            reset()
        }
    }
    return (
        <React.Fragment>
            <Button variant='text' children='Новая интеграция c amoCRM' onClick={openHandler}/>
            <Dialog open={open} onClose={closeHandler}>
                <form onSubmit={handleSubmit(registerHandler)}>
                <DialogTitle>
                    Регистрация новой интеграции с amoCRM
                </DialogTitle>                
                <DialogContent>
                    <DialogContentText sx={{mb: 1}}>Существующая интеграция будет заменена</DialogContentText>
                    <Box sx={{display: 'flex', flexFlow: 'column', gap: 2}}>
                        <FormTextField required control={control} name="url"           label= 'Аккаунт amoCRM' placeholder="https://example.amocrm.ru"/>
                        <FormTextField required control={control} name="clientId"      label= 'ID интеграции'/>
                        <FormTextField required control={control} name="clientSecret"  label= 'Секретный ключ'/>
                        <FormTextField required control={control} name="code"          label= 'Код авторизации'/>
                        <FormTextField required control={control} name="redirectUrl"   label= 'Ссылка для перенаправления' placeholder="https://mycompany.com"/>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' type="submit" children='Зарегистрировать'/>
                    <Button onClick={closeHandler} children='Отмена'/>
                </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    )

}