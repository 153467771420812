import {Theme, createTheme} from '@mui/material/styles'
declare module '@mui/material/styles' {
  interface Theme {
    controls: {
        borderColor(theme: Theme):string 
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    controls: {
        borderColor(theme: Theme):string 
    }

  }
}

export const defaultTheme = createTheme({
    controls: {
        borderColor(theme: Theme){
            return theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[300]
        }
    },
    palette: {
        primary: {
            main: '#006CBD',
        },
        secondary: {
            main: '#f07b6a',
        },
        background: {
            default: '#fafafa',
        }
    }   
 })
