import useSWR ,{ Fetcher, SWRConfiguration, BareFetcher } from 'swr'
import { 
    API_INFO, 
    API_WIDGET_DATA, 
    API_DASHBOARD, 
    API_LEAD_LOG, 
    API_LEAD_EVENT_DETAILS ,
    AppInfo, 
    WidgetData, 
    DashboardData, 
    LeadLog, 
    LeadLogReq, 
    LeadEventDetails, 
    LeadEventDetailsReq,
    UploadLogReq,
    UploadLog,
    API_UPLOAD_LOG,
    API_CONTROL,
    ControlPanelData
} from 'src/../../common/src/api'

import fetchData from './fetchData'
import { FetchDataError, ERR_UNKOWN } from './errors'
import useNotice from './useNotice'

interface FetcherParams {
    endpoint: string,
    params?: Record<string,any>
    refreshInterval?: number
}
const fetcher: Fetcher<any, FetcherParams> = ({endpoint,params}) => fetchData({
    method: 'get',
    url: `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
    params
}).then(({success, data, error})=>{
    if (!success)  {
        throw new FetchDataError(error || ERR_UNKOWN) 
    }
    return data
})

function useSWRFetcher<T>({endpoint, params, refreshInterval}: FetcherParams, config?: SWRConfiguration<T, Error, BareFetcher<T>> | undefined) {
    const {error, data, ...res} = useSWR<T, Error>({endpoint, params}, fetcher, {...config, refreshInterval})
    useNotice({error})
    return {...res, data, error, isLoading: !data && !error}
} 
interface ApiFetchWithParams<T extends Record<string, any> = Record<string,any>> extends ApiFetch {
    params?: T 
}
interface ApiFetch {
    refreshInterval?: number
}
export const useUploadLog =         (params: ApiFetchWithParams<UploadLogReq>) =>           useSWRFetcher<UploadLog>(       {endpoint: API_UPLOAD_LOG, ...params})
export const useLeadEventDetails =  (params: ApiFetchWithParams<LeadEventDetailsReq>) =>    useSWRFetcher<LeadEventDetails>({endpoint: API_LEAD_EVENT_DETAILS, ...params})
export const useLeadLog =           (params: ApiFetchWithParams<LeadLogReq>) =>             useSWRFetcher<LeadLog>(         {endpoint: API_LEAD_LOG, ...params})  
export const useAppInfo =           (params?: ApiFetch) =>                                  useSWRFetcher<AppInfo>(         {endpoint: API_INFO, ...params}) 
export const useWidgetData =        (params?: ApiFetch) =>                                  useSWRFetcher<WidgetData>(      {endpoint: API_WIDGET_DATA, ...params}, {refreshInterval: 5000})
export const useDashboard =         (params?: ApiFetch) =>                                  useSWRFetcher<DashboardData>(   {endpoint: API_DASHBOARD, ...params})
export const useControlPanel =      (params?: ApiFetch) =>                                  useSWRFetcher<ControlPanelData>({endpoint: API_CONTROL, ...params})

