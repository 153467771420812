import './App.css';
import './stylesheets/rc-notification.css'

import { ThemeProvider as MuiThemeProvider} from '@mui/material/styles'
import { ThemeProvider as AppThemeProvider} from '@emotion/react'
import { defaultTheme } from './components/styled';
import { AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ruLocaleFns from 'date-fns/locale/ru'

import { LoginScreen } from './screens/LoginScreen';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import AuthUsersOnly from './components/AuthUsersOnly'
import WidgetScreen from './screens/WidgetScreen';
import MainScreen from 'src/screens/MainScreen'
import AppLayout from 'src/components/AppLayout';
import WidgetStatus from './components/WidgetStatus';
import {CssBaseline} from '@mui/material'
import SnackbarProvider from './components/NoticeProvider';
import PubSubProvider from 'src/components/PubSubProvider'

function App() {
    const pageContent =   (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <AppLayout/>} >
                    <Route index element={<Navigate to='/app'/>}/>
                    <Route path="app/*" element={ <AuthUsersOnly><MainScreen path="app"/></AuthUsersOnly>}/>
                    <Route path="login" element={ <LoginScreen/>}/>
                    <Route path="auth"  element={ <AuthUsersOnly><WidgetStatus/></AuthUsersOnly> }/>
                </Route>
                <Route path="widget" element={<WidgetScreen/>}/>
            </Routes>
        </BrowserRouter>
    )
    return (
        <PubSubProvider>
        <SnackbarProvider>
            <MuiThemeProvider theme={defaultTheme}>
            <AppThemeProvider theme={defaultTheme}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocaleFns}>
                <CssBaseline/>
                {pageContent}
            </LocalizationProvider>
            </AppThemeProvider>
            </MuiThemeProvider>
        </SnackbarProvider>
        </PubSubProvider>
    )
}
export default App;
